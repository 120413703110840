import * as React from 'react';
import './index.css';
import Datafeed from './api/';
import {widget} from '../../charting_library';
import {Updatable} from './api/helpers';

/*
	exchange : 거래소
	Kind : 현물/선물 등등
	symbol : 코인명
*/


function getQuery(symbol) {
  const regex = new RegExp(`[\\?&]${symbol}=([^&#]*)`);
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {
  static defaultProps = {
    symbol: 'Binance:Future:BTCUSDT',
    interval: '1D',
    containerId: 'tv_chart_container',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: true,
    autosize: false,
    studiesOverrides: {},
  };

  tvWidget = null;
  currentSym = null;

  chartService = {};

  getParameterByName(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  getParametersByName(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.getAll(name);
  }

  colorValuesToString(values) {
    if (values == null) {
      return null;
    }

    const r = values[0];
    const g = values[1];
    const b = values[2];
    const a = values[3];

    return `rgba(${r},${g},${b},${a})`;
  }

// return array of [r,g,b,a] from any valid color. if failed returns undefined
  colorValues(color) {
    if (!color)
      return;
    if (color.toLowerCase() === 'transparent')
      return [0, 0, 0, 0];
    if (color[0] === '#') {
      if (color.length < 7) {
        // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
        color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color.length > 4 ? color[4] + color[4] : '');
      }
      return [parseInt(color.substring(1, 2), 16),
        parseInt(color.substring(3, 2), 16),
        parseInt(color.substring(5, 2), 16),
        color.length > 7 ? parseInt(color.substring(7, 2), 16) / 255 : 1];
    }
    if (color.indexOf('rgb') === -1) {
      // convert named colors
      var temp_elem = document.body.appendChild(document.createElement('fictum')); // intentionally use unknown tag to lower chances of css rule override with !important
      var flag = 'rgb(1, 2, 3)'; // this flag tested on chrome 59, ff 53, ie9, ie10, ie11, edge 14
      temp_elem.style.color = flag;
      if (temp_elem.style.color !== flag)
        return; // color set failed - some monstrous css rule is probably taking over the color of our object
      temp_elem.style.color = color;
      if (temp_elem.style.color === flag || temp_elem.style.color === '')
        return; // color parse failed
      color = getComputedStyle(temp_elem).color;
      document.body.removeChild(temp_elem);
    }
    if (color.indexOf('rgb') === 0) {
      if (color.indexOf('rgba') === -1)
        color += ',1'; // convert 'rgb(R,G,B)' to 'rgb(R,G,B)A' which looks awful but will pass the regxep below
      return color.match(/[\.\d]+/g).map(function (a) {
        return +a;
      });
    }
  }

  componentDidMount() {
    this.getInputList = (obj) => {
      let keys = [];
      let results = [];
      for (let key in obj) {
        keys.push(key);
      }
      keys.sort();
      let len = keys.length;
      for (let i = 0; i < len; i++) {
        let k = keys[i];
        results.push(obj[k]);
      }
      return results;
    };

    this.chartService.saveCurrentChart = (obj) => {
      // this.cookies.set('tv-zz','1')
      localStorage.setItem('tv-chart', JSON.stringify(obj));
      // localStorage.setItem(this.currentSym,JSON.stringify(obj))
    };

    this.chartService.loadCurrentChart = (obj) => {
      let result = localStorage.getItem('tv-chart');
      // let result = localStorage.getItem(this.currentSym)
      if (result) {
        let data = JSON.parse(result);
        let curr;
        // GET name, input FROM chart information
        for (let p_key in data.charts[0].panes) {
          for (let s_key in data.charts[0].panes[p_key].sources) {
            let s = data.charts[0].panes[p_key].sources[s_key];
            if (s.type == 'Study') {
              let input_list = this.getInputList(s.state.inputs);
              tvWidget.activeChart().createStudy(s.state.description, false, false, input_list, null, {disableUndo: true});
            }


          }
        }

        const positionQuery = this.getParameterByName('positions');
        if (positionQuery !== '') {
          const positionString = decodeURIComponent(atob(positionQuery));
          try {
            const positionList = JSON.parse(positionString);

            for (let i = 0; i < positionList.length; i++) {
              const positionItem = positionList[i];
              const orderLine = addOrderLine(positionItem);
            }
          } catch (e) {
            console.debug(e);
          }
        }

        // tvWidget.save((saveObject) => {
        // 	curr = saveObject.charts[0].panes[0].sources[0]
        // 	data.charts[0].panes[0].sources[0] = curr
        // 	tvWidget.load(data)
        // })

        // tvWidget.setSymbol(this.currentSym,'5',() => null)
        // tvWidget
        // tvWidget.save((saveObject) => {
        // 	tvWidget.load(JSON.parse(result))
        // })
      } else {
        tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [7, 'close', 0], null, {disableUndo: true});
        tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [25], null, {disableUndo: true});
        tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [99], null, {disableUndo: true});
        // tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [7], null, {disableUndo: true})
        // tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [25], null, {disableUndo: true})
        // tvWidget.activeChart().createStudy('Moving Average Exponential', false, false, [99], null, {disableUndo: true})
      }
    };
    let qExchange = 'Binance';
    let qKind = getQuery('kind') ?? 'Future';
    let qSymbol = getQuery('symbol');
    let sym = qSymbol ? `${qExchange}:${qKind}:${qSymbol}` : `Binance:Future:BTCUSDT`;
    // let sym = getQuery('exchange') && getQuery('kind') && getQuery('symbol') ? `${getQuery('exchange')}:${getQuery('kind')}:${getQuery('symbol')}` : this.props.symbol;
    let timezone = getQuery('timezone') || 'Asia/Seoul';
    let interval = getQuery('interval') || this.props.interval;
    const isMobile = getQuery('mobile') === 'mobile';
    const mobileDisabled = [];
    const mobileEnabled = [];

    if (isMobile) {
      mobileDisabled.push(...[
        'left_toolbar',
        'go_to_date',
        'volume_force_overlay',
        'header_saveload',
        'header_indicators',
        'header_compare',
        'header_undo_redo',
        'header_settings',
        'header_fullscreen_button',
        'header_screenshot',
        'timeframes_toolbar',
        'scales_date_format',
        'legend_widget',
      ]);

      mobileEnabled.push(...[
        'show_average_close_price_line_and_label',
      ]);
    }

    console.log('sym', sym);

    const widgetOptions = {
      debug: false,
      symbol: sym,
      datafeed: Datafeed,
      interval: interval,
      container: this.props.containerId,
      library_path: this.props.libraryPath,
      locale: getQuery('lang') || 'ko',
      disabled_features: [
        ...mobileDisabled,
        // 'use_localstorage_for_settings',
        'save_chart_properties_to_local_storage',
        // 검색기능
        'header_symbol_search',
        // 'header_undo_redo',
        // 'header_compare',
        // 'header_indicators',
        // 'legend_widget',
        // 'header_interval_dialog_button',
      ],
      enabled_features: [
        ...mobileEnabled,
        'move_logo_to_main_pane',
        'caption_buttons_text_if_possible',
      ],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides,
      timezone: timezone,
      toolbar_bg: 'transparent',
      enable_publishing: false,
      hide_side_toolbar: false,
      theme: this.getParameterByName('theme') ?? 'light',
      // custom_css_url: '/style.css',
      // loading_screen: {
      // 	backgroundColor: '#101033',
      // 	foregroundColor: '#101033',
      // },
      favorites: {
        intervals: ['1', '3', '5', '30', '1h', '1D', '1W'],
      },
      time_frames: [],
      overrides: {
        // "mainSeriesProperties.showCountdown": true,
        // "paneProperties.background": "#101037",
        // "paneProperties.backgroundType": "solid",

        // "paneProperties.backgroundGradientStartColor": "#ffffff",
        // "paneProperties.backgroundGradientEndColor": "#ffffff",

        // "paneProperties.vertGridProperties.color": "rgba(255, 255, 255, 0.06)",
        // "paneProperties.vertGridProperties.style": 1,
        // "paneProperties.horzGridProperties.color": "rgba(255, 255, 255, 0.06)",
        // "paneProperties.horzGridProperties.style": 1,

        // "paneProperties.crossHairProperties.color": "#aaaaaa",
        // "paneProperties.crossHairProperties.transparency": 0,
        // "paneProperties.crossHairProperties.width": 1,

        // "scalesProperties.backgroundColor": "#ffffff",
        // "scalesProperties.lineColor": "rgba(255, 255, 255, 0.14)",
        // "scalesProperties.textColor": "#b7c8f5",

        // "mainSeriesProperties.highLowAvgPrice.highLowPriceLinesVisible": true,
        // "mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible": true,
      },
    };
    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;
    tvChart = tvWidget;


    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        // initialize
        this.currentSym = widgetOptions.symbol;

        this.chartService.loadCurrentChart();
        // tvWidget.setSymbol(sym);

        // var a = tvWidget.activeChart().getStudyById("gYRaMv")

        tvWidget.subscribe('study', () => {
          tvWidget.save((saveObject) => this.chartService.saveCurrentChart(saveObject));
        });
        tvWidget.subscribe('mouse_down', () => {
          tvWidget.save((saveObject) => this.chartService.saveCurrentChart(saveObject));
        });
        tvWidget.subscribe('study_properties_changed', () => {
          tvWidget.save((saveObject) => this.chartService.saveCurrentChart(saveObject));
        });

      });
    });
  }

  componentWillUnmount() {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
      Updatable.clear();
    }
  }

  render() {
    return (
      <div
        id={this.props.containerId}
        className={'TVChartContainer'}
      />
    );
  }
}
