import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';

if (process.env.NODE_ENV === 'production') {
	console.log = () => {}
	console.error = () => {}
	console.debug = () => {}
  }


ReactDOM.render(
	React.createElement(App),
	document.getElementById('root')
);
