var moment = require('moment')


// const supportedResolutions = ["1", "3", "5", "15", "30", "60", "120", "240", "1D", "1W"]
const resolutionMap = {
	"1": "1m",
	"3": "3m",
	"5": '5m',
	'15': '15m',
	'30': '30m',
	'60': '1h',
	"120": '2h',
	'240': '4h',
	'1D': '1d',
	"1W": '1w',
	"1M": '1M'
}

export const APIKey = 'e9f96456e09ce102c8ab7e2bbb9bd7e779b5f0e195d1b3b0dcd8ebf1a30237ab'

export function getUpbitUrl(resolution) {
	if(resolution == '1D')
		return 'https://api.upbit.com/v1/candles/days'
	else if (resolution =='1W')
		return 'https://api.upbit.com/v1/candles/weeks'
	else if (resolution =='1M')
		return 'https://api.upbit.com/v1/candles/months'
	else
		return `https://api.upbit.com/v1/candles/minutes/${resolution}`
}


export function initializeWebSocket(ws, handler) {
	ws.addEventListener('open', () => {
	})

	ws.addEventListener('message', handler)

	ws.addEventListener('close', () => {
		console.error('===Web Socket closed')
	})

	ws.addEventListener('error', err => {
		console.error('==== Web Socket error', err)
	})
	return ws
}

export function initializeUpbitWebSocket(ws, payload, handler) {
	ws.addEventListener('open', () => {
		ws.send(payload)
	})

	ws.addEventListener('message', handler)

	ws.addEventListener('close', () => {
		console.error('=== Upbit Web Socket closed')
	})

	ws.addEventListener('error', err => {
		console.error('==== Web Socket error', err)
	})
	return ws
}

export function parseUpbitCandleData(d) {
	return {
		// 받는 시각
		time: parseInt(moment.utc(d.candle_date_time_utc).format('x'), 10),
		low: d.low_price,
		high: d.high_price,
		open: d.opening_price,
		close: d.trade_price,
		volume: d.candle_acc_trade_volume,
		debug : d.candle_date_time_utc
	}
}

export function parseBinanceCandleData(d) {
	let [time, open, high, low, close, volume, closeTime, assetVolume, trades, buyBaseVolume, buyAssetVolume, ignored] = d
	return {
		time: parseFloat(time),
		low: parseFloat(low),
		high: parseFloat(high),
		open: parseFloat(open),
		close: parseFloat(close),
		volume: parseFloat(volume)
	}
}

export function translateBinanceResolution(resolution) {
	return resolutionMap[resolution]
}

export async function tester(symbolInfo, resolution, from, to, first, limit) {
	// console.info({ binance })
	// If binance

	// let futuresExchangeInfo = await client.futuresExchangeInfo()
}

/*
	이걸로 가공
	// symbol: symbol.short,
	// full_name: symbol.full,
	// description: symbol.short,
	// exchange: exchange.value,
	// type: 'crypto',
*/
export async function getBinanceAllSymbols() {
	let f = await getBinanceFutureSymbols()
	let d = await getBinanceCOINMSymbols()
	let s = await getBinanceSpotSymbols()

	return [...f, ...d, ...s]
}


export async function getUpbitAllSymbols() {
	const response = await fetch(`https://api.upbit.com/v1/market/all`)
	let ExchangeInfo = await response.json();
	// let ExchangeInfo = await binance.exchangeInfo()
	let ret = ExchangeInfo.map(d => {
		return {
			symbol: d.market,
			full_name: 'Upbit:Spot:' + d.market,
			description: `${d.market}`,
			exchange: 'Upbit',
			type: 'crypto',
		}
	})
	return ret
}

export function getBinanceMarketType(desc) {
	var split_symbol = desc.split(/[:]/)
	let market = split_symbol[1]

	if (market == 'Future')
		return 'Future'
	else if (market == 'Spot')
		return 'Spot'
	else if (market == 'COINM')
		return 'COINM'
}

export function sortBinanceSymbols(desc) {
	var split_symbol = desc.split(/[:]/)
	let market = split_symbol[1]

	if (market == 'Future') {
		// Binance:Future:BTCUSDT_230331
		const symbol = split_symbol[split_symbol.length - 1];
		return 'https://fapi.binance.com/fapi/v1/continuousKlines';
		// if (symbol.split('_').length === 1) {
		// 	return 'https://fapi.binance.com/fapi/v1/klines'
		// } else {
		// 	return 'https://fapi.binance.com/fapi/v1/continuousKlines';
		// }
	}
	else if (market == 'Spot')
		return 'https://api.binance.com/api/v3/klines'
	else if (market == 'COINM') {
		// Binance:COINM:BTCUSD_PERP
		const symbol = split_symbol[split_symbol.length - 1];
		return 'https://dapi.binance.com/dapi/v1/continuousKlines'
		// if (symbol.split('_')[1] === 'PERP') {
		// 	return 'https://dapi.binance.com/dapi/v1/klines'
		// } else {
		// 	return 'https://dapi.binance.com/dapi/v1/continuousKlines'
		// }
	}
}




export async function getBinanceFutureSymbols() {
	const response = await fetch(`https://fapi.binance.com/fapi/v1/exchangeInfo`)
	let ExchangeInfo = await response.json();
	// let ExchangeInfo = await binance.exchangeInfo()
	let ret = ExchangeInfo.symbols.map(d => {
		return {
			symbol: d.symbol,
			full_name: 'Binance:Future:' + d.symbol,
			description: `${d.symbol}`,
			exchange: 'Binance',
			type: 'crypto',
			tick_size : d.filters[0].tickSize,
			contract_type: d.contractType,
		}
	})
	return ret
}


export async function getBinanceCOINMSymbols() {
	const response = await fetch(`https://dapi.binance.com/dapi/v1/exchangeInfo`)
	let ExchangeInfo = await response.json();
	// let ExchangeInfo = await binance.exchangeInfo()
	let ret = ExchangeInfo.symbols.map(d => {
		return {
			symbol: d.symbol,
			full_name: 'Binance:COINM:' + d.symbol,
			description: `${d.symbol}`,
			exchange: 'Binance',
			type: 'crypto',
			tick_size : d.filters[0].tickSize,
			contract_type: d.contractType,
		}
	})
	return ret
}



export async function getBinanceSpotSymbols() {
	const response = await fetch(`https://api.binance.com/api/v3/exchangeInfo`)
	let ExchangeInfo = await response.json();
	let ret = ExchangeInfo.symbols.map(d => {
		return {
			symbol: d.symbol,
			full_name: 'Binance:Spot:' + d.symbol,
			description: `${d.symbol}`,
			exchange: 'Binance',
			type: 'crypto',
			tick_size : d.filters[0].tickSize,
		}
	})
	return ret
}


// Aggregate을 돌려줌
export function getAggregate(val) {
	let ret;
	if (val === '1D') {
		ret = 1
	}
	else if (val === '1W') {
		ret = 7
	}
	else if (val === '1M') {
		ret = 30
	}
	else if (val >= 60) {
		ret = parseInt(val, 10) / 60
	}
	else {
		ret = parseInt(val, 10)
	}
	return ret;
}

// Gets scale and return URL
export function parseResolution(res) {
	let url = ''
	if (res === '1D' || res === '1W' || res === '1M') {
		url = 'data/histoday'
	} else if (res >= 60) {
		url = 'data/histohour'
	}
	else {
		url = 'data/histominute'
	}
	return url
}


// Make requests to CryptoCompare API
export async function makeApiRequest(path) {
	try {
		const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
		return response.json();
	} catch (error) {
		throw new Error(`CryptoCompare request error: ${error.status}`);
	}
}

// Make requests to CryptoCompare API with Authorization
export async function makeApiRequestWithKey(path) {
	try {
		const response = await fetch(`https://min-api.cryptocompare.com/${path}`,
			{
				headers: {
					"Authorization": 'Apikey ' + APIKey
				}
			}
		);
		return response.json();
	} catch (error) {
		throw new Error(`makeApiRequestWithKey error: ${error.status}`);
	}
}



// Generate a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
	const short = `${fromSymbol}/${toSymbol}`;
	return {
		short,
		full: `${exchange}:${short}`,
	};
}

// It parses a crypto pair symbol (full value returned from generateSymbol) and returns all parts of this symbol:
export function parseFullSymbol(fullSymbol) {
	const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
	if (!match) {
		return null;
	}

	/*
	Example
		0: "Bitfinex:BTC/USD"
		1: "Bitfinex"
		2: "BTC"
		3: "USD"
	*/
	return {
		exchange: match[1],
		fromSymbol: match[2],
		toSymbol: match[3],
	};
}

export class Updatable {
	static #canUpdate = true;
	static #update;

	static {
		this.#update = setInterval(() => this.#canUpdate = true, 500);
	}

	static clear = () => {
		clearInterval(this.#update);
	}

	static canUpdate = () => this.#canUpdate;

	static blockUpdate = () => this.#canUpdate = false;
}
