import historyProvider from './historyProvider'
import stream from './stream'
import {
	generateSymbol,
	tester,
	getBinanceAllSymbols,
	getUpbitAllSymbols
} from './helpers.js';

const binanceSupportedResolutions = ["1", "3", "5", "15", "30", "60", "240", "1D", "1W"]
const upbitSupportedResolutions = ["1", "3", "5", "15", "30", "60", "240", "1D", "1W"]

let intraDayMultipliers = ["1", "3", "5", "15", "30", "60", "120", "240"]


const config = {
	supported_resolutions: upbitSupportedResolutions,
	exchanges: [
		{
			value: 'Binance',
			name: 'Binance',
			desc: '바이낸스 거래소',
		},
		{
			value: 'Upbit',
			name: 'Upbit',
			desc: '업비트 거래소',
		},
	],
	symbols_types: [{
		name: 'crypto',
		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'crypto',
	},
	],
};

// 모든 symbols를 가져온다
/*
현재구현
	Example
	{symbol: 'BTCUSDT', full_name: 'Binance:Spot:BTCUSDT', description: 'BTCUSDT / Spot', exchange: 'Binance', type: 'crypto'}
*/
async function getAllSymbols() {
	return [...await getBinanceAllSymbols(), /*...await getUpbitAllSymbols()*/]
}

export default {
	onReady: cb => {
		setTimeout(() => cb(config), 0)

	},
	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
		const symbols = await getAllSymbols();
		const newSymbols = symbols.filter(symbol => {
			const isExchangeValid = exchange === '' || symbol.exchange === exchange;
			const isFullSymbolContainsInput = symbol.full_name
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;
			return isExchangeValid && isFullSymbolContainsInput;
		});
		onResultReadyCallback(newSymbols);
	},
	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
		// expects a symbolInfo object in response
		//symbolName은 이전에 getsymbol 함수에서 만든 full_name
		const symbols = await getAllSymbols();

		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			console.error('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}

		// name에 symbol이 들어가도록, exchange 있으니까 이 두개가 기준
		// full_name을 ticker로 쓴다. Description에 binance의 경우 market 종류를 넣음
		var symbol_stub = {
			ticker: symbolItem.full_name,
			full_name: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			type: 'crypto',
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: 'Binance',
			minmov: 1,
			pricescale: symbolItem.tick_size ? Math.ceil(1 / symbolItem.tick_size) :100000000,
			has_intraday: true,
			contract_type: symbolItem.contract_type,
			intraday_multipliers: intraDayMultipliers,
			supported_resolution: binanceSupportedResolutions,
			volume_precision: 8,
			data_status: 'streaming',
		}
		setTimeout(function () {
			onSymbolResolvedCallback(symbol_stub)
		}, 0)


		// onResolveErrorCallback('Not feeling it today')

	},
	getBars: function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
		const { from, to, firstDataRequest } = periodParams;
		symbolInfo.exchange = 'Binance';

		historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest)
			.then(bars => {
				if (bars.length) {
					onHistoryCallback(bars, { noData: false })
				} else {
					onHistoryCallback(bars, { noData: true })
				}
				return null;
			}).catch(err => {
				console.error({ err })
				onErrorCallback(err)
			})

	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
		stream.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback)
	},
	unsubscribeBars: subscriberUID => {
		stream.unsubscribeBars(subscriberUID)
	},
	calculateHistoryDepth: (resolution, resolutionBack, intervalBack) => {
		//optional
		// while optional, this makes sure we request 24 hours of minute data at a time
		// CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
		return resolution < 60 ? { resolutionBack: '1D', intervalBack: '1' } : undefined
	},
	getMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
	},
	getTimeScaleMarks: (symbolInfo, startDate, endDate, onDataCallback, resolution) => {
		//optional
	},
	getServerTime: cb => {
	}
}
